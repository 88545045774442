<template>
  <div class="configure">
    <!-- 面包屑 -->
    <div class="crumb">
      <Breadcrumb separator=">">
        <Breadcrumb-item :to="{ path: '/' }">悦心车</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: '/carlist' }">车辆列表</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: `/detail?id=${id}` }"
          >车辆详情</Breadcrumb-item
        >
        <Breadcrumb-item :to="{ path: '/carlist/configure' }"
          >车辆配置</Breadcrumb-item
        >
      </Breadcrumb>
    </div>
    <div class="title">{{ brand }}{{ carname }}</div>

    <div class="special">
      特色配置：
      <span>{{ configure.feature }}</span>
    </div>

    <div class="configure-box">
      <div class="configure-list">
        <div class="title">基本参数</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.basic_items"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">车身</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.car_body"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">发动机</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.engine"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">变速箱</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.gearbox"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">底盘转向</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.chassis"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">车轮制动</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.wheel_brake"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">车轮制动</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.gearbox"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">主/被动安全装备</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.safety_items"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">辅助/操控配置</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.control"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">外部/防盗配置</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.outer"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">内部配置</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.inner"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">座椅配置</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.seat"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">多媒体配置</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.multimedia"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">灯光配置</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.lighting"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">玻璃/后视镜</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.glass"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
      <div class="configure-list">
        <div class="title">空调/冰箱</div>
        <div class="list-table">
          <i-table
            :columns="columns1"
            :data="configure.aircondition"
            :show-header="false"
            :border="true"
          ></i-table>
        </div>
      </div>
    </div>

    <div class="notes">
      注：以上参配信息仅供参考，实际参数配置以售卖车辆为准
    </div>
  </div>
</template>

<script>
import { getSpecParams } from "@/api/index.js";

export default {
  data() {
    return {
      id: "",
      brand: "",
      carname: "",
      configure: [],
      columns1: [
        {
          title: "配置",
          key: "k"
        },
        {
          title: "参数",
          key: "v"
        }
      ]
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.brand = this.$route.query.brand;
    this.carname = this.$route.query.car;
    // 车辆配置参数
    this.getSpecParams();
  },
  methods: {
    // 车辆配置参数
    getSpecParams() {
      getSpecParams({
        id: this.id
      }).then(res => {
        this.configure = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.configure {
  width: 100%;
  padding-bottom: 60px;
  box-sizing: border-box;
  .crumb {
    width: 100%;
    height: 20px;
    margin-top: 50px;
    /deep/ .ivu-breadcrumb {
      color: #333;
      font-weight: 500;
      .ivu-breadcrumb-item-separator {
        color: #333;
        font-weight: 500;
      }
    }

    .ivu-breadcrumb > span:last-child {
      color: #333;
      font-weight: 500;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    margin-top: 35px;
  }
  .special {
    font-size: 14px;
    font-weight: 300;
    color: rgba(102, 102, 102, 1);
    margin-top: 15px;
  }
  .configure-box {
    margin-top: 10px;
    .configure-list {
      margin-top: 30px;
      .title {
        width: 100%;
        height: 50px;
        background-color: #f6f5f6;
        font-size: 16px;
        color: #333;
        line-height: 50px;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }
  }
  .notes {
    width: 100%;
    margin-top: 30px;
    font-size: 14px;
    color: #666;
    text-align: right;
  }
}
</style>
